/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
 @use 'stream-chat-angular/src/assets/styles/scss/index.scss';

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css'; /* For the snow theme */
@import '~quill/dist/quill.bubble.css'; /* Optional: For the bubble theme */

@import '@fontsource/geist-sans/index.css';
@import "@fontsource/open-sans/index.css";


/* Optional CSS utils that can be commented out */
// @import "@ionic/angular/css/padding.css";
// @import "@ionic/angular/css/float-elements.css";
// @import "@ionic/angular/css/text-alignment.css";
// @import "@ionic/angular/css/text-transformation.css";
// @import "@ionic/angular/css/flex-utils.css";

//adding scroll-hidden class to hide scroll bar globally wherever the class is applied
.scroll-hidden::-webkit-scrollbar {
    display: none;
}

.bay-assignment-selected {
    border: 3px solid #49cda3;
}


//TOAST STYLING
ion-toast.error-toast {
    --background: rgb(254, 226, 226);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(153, 27, 27);
}

ion-toast.warning-toast {
    --background: rgb(255, 237, 213);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(154, 52, 18);
}

ion-toast.success-toast {
    --background: rgb(220, 252, 231);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(22, 101, 52);
}

ion-toast.information-toast {
    --background: rgb(241, 245, 249);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: rgb(30, 41, 59);
}

ion-toast.move-reservation-or-remove-table-toast {
    &::part(button){
        --button-color: rgb(154, 52, 18);
    }
}


//DATE TIME STYLING
ion-datetime {
    --background: #171720;
}

// Define light mode styles
@media (prefers-color-scheme: light) {
    ion-datetime {
        --background: #fff;
    }
}
  
// Define dark mode styles
@media (prefers-color-scheme: dark) {
    ion-datetime {
        --background: #171720;
    }
}

ion-datetime-button.unselectedDateTimeButton {
    &::part(native) {
        background-color: #c0c0c5 !important;
        color: #171720 !important;
    }
}

ion-datetime-button.selectedDateTimeButton {
    &::part(native) {
        background-color: #c8f0e3 !important;
        color: #256752 !important;
    }
}


//MODAL STYLING
ion-modal.pin-modal::part(backdrop) {
    background-color: rgba(0, 0, 0, 1);
}


ion-modal.add-reservation-socials-modal,
ion-modal.add-company-modal,
ion-modal.update-reservation-date-time-modal,
ion-modal.update-reservation-guest-genders-modal {
    --min-height: 250px;
    --height: 250px;
    --max-height: 250px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
    }
}

ion-modal.update-customer-phone-modal,
ion-modal.update-customer-job-info-modal {
    --min-height: 400px;
    --height: 400px;
    --max-height: 400px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
        --height: 356px;
    }
}

ion-modal.update-customer-birth-date-modal {
    --min-height: 200px;
    --height: 200px;
    --max-height: 200px;
    --width: 300px;

    ion-toolbar {
        --background: #171720;
        
        ion-buttons {
            ion-button {
                --color: #fff;
            }
        }
    }

    ion-content {
        --background: #2E2E3F;
        --height: 356px;
    }
}


//ADD TAG MODAL
.add-tag-modal {
    --height: 300px;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.add-tag-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

.add-tag-modal ion-toolbar {
    --background: rgb(68, 51, 144);
    --color: white;
}

.add-tag-modal ion-content,
.add-tag-modal ion-content {
    --background: rgb(46, 46, 63);
}


// STREAM CHAT STYLING
.str-chat__theme-dark {
    --str-chat__primary-color: #009688;
}
